/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';
import { withStyles } from '@material-ui/core/styles';
import React, { Children, cloneElement } from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  SearchInput,
  ShowButton,
  TextField,
  EmailField,
  SelectInput,
  FunctionField
} from 'react-admin';
import {isAdmin} from "./utils";
import {Avatar} from "@material-ui/core";

const ActionToolbar = withStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
  },
})(({ classes, children, ...props }) => (
  <div className={classes.toolbar}>
    {Children.map(children, button => cloneElement(button, props))}
  </div>
));

export const AvatarField = ({ source, record = {} }) => (<Avatar src={`http://minepic.org/avatar/${record[source]}`}/>);

const UserFilter = ({permissions, ...props}) => (
  <Filter {...props} classes="form">
    <SearchInput source="search" alwaysOn/>
    {isAdmin(permissions) ? <SelectInput alwaysOn source="role" choices={[
      { id: 'END_USER', name: 'END_USER'},
      { id: 'ADMIN', name: 'ADMIN' },
      { id: 'SUPER_ADMIN', name: 'SUPER_ADMIN'},
    ]} /> : null}
  </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
  return isAdmin(permissions)
    ? Promise.resolve('show')
    : Promise.resolve('show');
});

const UserList = ({permissions, ...props}) => (
  <List
    {...props}
    filters={<UserFilter permissions={permissions}/>}
    sort={{field: 'email', order: 'ASC'}}
  >
        <Datagrid
          rowClick={rowClick(permissions)}
        >
          <TextField source="id"/>
          <AvatarField source="id"/>
          <FunctionField render={record => `${record.firstName} ${record.lastName}`}/>
          <EmailField source="email" label="Email"/>
          <EmailField source="role" label="Role"/>
          <DateField source="createdAt" label="Created"/>
          {isAdmin(permissions) && <TextField source="role"/>}
          <ActionToolbar>
            <EditButton />
            <ShowButton />
          </ActionToolbar>
        </Datagrid>
  </List>
);

export default UserList;
